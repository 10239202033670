<template>
  <div id="content" class="content" ref="content">
    <MainBanner id="main" ref="main"></MainBanner>
    <section v-if="showPages">
      <Block :class="page.component" v-for="page in pagesInfo" :key="page.component" :page="page" :ref="page.component"></Block>
    </section>
    <Footer/>
  </div>
</template>
<script>
  import MainBanner from "@/components/layout/pages/MainBanner";
  import Block from "@/components/layout/Block";
  import Footer from "./footer/Footer";
  // import ExtraBanner from "../extra/ExtraBanner.vue";

  export default {
    name: "Content",
    components: {
      Block,
      MainBanner,
      Footer
    },
    data: () => ({
      showPages: true,
      pagesInfo: [
        {title: 'Новый Год', color: "#d41013", component: 'extraBanner'},
        {title: 'Размещение', color: '#d41013', component: 'accommodation'},
        {title: 'SPA Зона', color: '#ffc629', component: 'sauna'},
        {title: 'О Нас', color: '#ffc629', component: 'about'},
        {title: 'Контакты', color: '#ffc629', component: 'contact'},
      ]
    }),
    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
      goTo(item) {
        try {
          let page = this.$refs[item.route].$el ?? this.$refs[item.route][0].$el;
          let c = this.$refs.content;
          let to = page.getBoundingClientRect().top;
          let h = this.isMobile() ? 70 : 110;
          c.scrollTo({top: c.scrollTop + to - h, behavior: 'smooth'});
        } catch (e){
          console.log(e);
        }
      }
    },
    async mounted() {
      window.addEventListener('DOMContentLoaded', () => {
        this.$root.$emit('loaded');
        if (this.$route.params.page && this.$route.params.page !== 'main') {
          this.goTo({ route: this.$route.params.page});
        }
      })
      setTimeout(function(){
        if (this.$route.params.page && this.$route.params.page !== 'main') {
          this.goTo({ route: this.$route.params.page});
        }
      }.bind(this), 500);
      this.$root.$on('goToPage', (item) => this.goTo(item));
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');


.content{
  position: fixed;
  padding-top: 110px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 2px solid #000;
}
@media (max-width: 480px) {
  .content {
    padding-top: 70px;
  }
  .content .footer{
    height: auto!important;
  }
}
.content > #home{
  padding: 0;
}
.content > section > * {
  min-height: calc(100vh - 110px);
  position: relative;
}
.content .footer{
  min-height: unset;
  /*height: 18rem;*/
  padding-top: 1rem;
  padding-bottom: 4rem;
}
/*.content > .contact{*/
/*  height: auto!important;*/
/*}*/
.content > #home {
  padding-top: 0;
}
</style>
<style>
.extraBanner{
  min-height: unset!important;
}
</style>
