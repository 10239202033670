<template>
  <div class="extra">
    <div class="extra-banner-wrap block">
      <div class="extra-banner">
<!--        <div class="extra-banner-logo">-->
<!--        </div>-->
<!--        <div class="extra-banner-title">-->
<!--        </div>-->
        <div class="extra-banner-content">
         <div v-for="i in images" :key="i.key" class="extra-banner-img">
           <img :src="i.src" @click="openImage(i.src)">
         </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import ny1 from '@/assets/2025/ny1.jpg';
import ny2 from '@/assets/2025/ny2.jpg';

export default {
  name: "ExtraBanner",
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: i});
    }
  },
  data: () => ({
    images: [
      {k: 'ny2', src: ny2, alt: 'invitation'},
      {k: 'ny1', src: ny1, alt: 'menu'},
    ],
  }),
}
</script>

<style scoped>
.extra{
  /*padding: 1rem 0;*/
}
.extra-banner-wrap{
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.extra-banner{
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.extra-banner-logo{
  max-width: 300px;
}
.extra-banner-title{
  font-family: Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #004664;
  text-transform: uppercase;
  line-height: 1.1;
}
.extra-banner-text{
  font-size: 13pt;
}
.extra-banner-text a{
  text-decoration: underline;
}
.extra-banner-text ul{
  list-style: none;
}
.extra-banner-text ul li{
  padding: 8px 0;
}
.extra-banner-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  max-height: 400px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 16px;
}
.extra-banner-img{
  text-align: center;
  max-width: 400px;
  background-color: #fff;
}
.extra-banner-img img{
  cursor: pointer;
  max-height: 100%;
}

@media only screen and (max-width: 480px) {

  .extra-banner-wrap{
    height: auto;
    padding: 1rem 2rem;
  }
  .extra-banner{
    flex-wrap: wrap;
    gap: 12px;
  }
  .extra-banner-image{
    min-height: 350px;
    width: 100%;
  }
}
</style>